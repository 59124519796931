import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { MegaMenuDrawer, MegaMenuItemButton, MegaMenuItemLink, MegaMenuWrapper } from './NavigationMenuDesktop.style'
import MegaMenuContent from './components/MegaMenuContent'
import { useGetMegaMenuQuery } from '../../../../../../features/cms/query'
import { setCloseDrawerMegaMenu, setOpenDrawerMegaMenu } from '../../../../../../features/ui/action'
import { openDrawerMegaMenuSelector } from '../../../../../../features/ui/selector'
import { IContentMegaMenu } from '../../../../../../types/cms'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'
import { controlTeaserTarget } from '../../../../../../features/cms/utils'
import { useSearchParams } from 'next/navigation'
import { AppState } from '@redux/store'
import { formatElementString } from '@foundation/analytics/tealium/lib'
import useScrollingUp from '@hooks/useScrollingUp/useScrollingUp'

const NavigationMenuDesktop: React.FC = () => {
  const { id, isOpen } = useSelector(openDrawerMegaMenuSelector)
  const { locale } = useRouter()
  const searchParams = useSearchParams()
  const previewDate = searchParams.get('previewDate')
  const filterRulesLocaleOverride = searchParams.get('filterRulesLocaleOverride')
  const header = useSelector((s: AppState) => s.cms.header)
  const { data } = useGetMegaMenuQuery({
    locale,
    ...(previewDate && { previewDate }),
    ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
  })
  const hasScrolled = useScrollingUp()

  const contentMegaMenu = data?.contentMegaMenu || header?.navigation?.children || []

  // NOTE: remove duplicate items from mega menu
  const seen = {}
  const uniqueMenuItems = contentMegaMenu.filter(item => {
    return Object.hasOwn(seen, item.id) ? false : (seen[item.id] = true)
  })

  return (
    <MegaMenuWrapper>
      {uniqueMenuItems.map(item => (
        <React.Fragment key={item.id}>
          <MegaMenuItems item={item} />
        </React.Fragment>
      ))}
      <MegaMenuDrawer open={isOpen} hasScrolled={hasScrolled ?? false}>
        <MegaMenuContent isContentVisible={isOpen} content={contentMegaMenu.find(item => item.id === id)} />
      </MegaMenuDrawer>
    </MegaMenuWrapper>
  )
}

const MegaMenuItems: React.FC<{ item: IContentMegaMenu }> = ({ item }) => {
  const dispatch = useDispatch()
  const { basePath } = useStoreIdentity()
  const { id } = useSelector(openDrawerMegaMenuSelector)

  const openDrawerMegaMenu = (selectId: string, selectCategory?: string) => {
    if (id === selectId) {
      dispatch(setCloseDrawerMegaMenu())
      return
    }
    dispatch(setOpenDrawerMegaMenu(selectId, selectCategory || ''))
  }

  const isExternalOrTeaser = ['CMExternalLink', 'CMTeaser'].includes(item.type)
  const link = isExternalOrTeaser
    ? item.type === 'CMTeaser'
      ? controlTeaserTarget(item?.teaserTargets)
      : item.formattedUrl
    : `${basePath}${item.formattedUrl ?? ''}`

  return item.type === 'CMExternalPage' ? (
    <MegaMenuItemButton
      key={item.id}
      data-element-id={`MainNav_${formatElementString(item.name)}`}
      data-description={`${item?.name}`}
      active={item.id === id}
      onClick={() => openDrawerMegaMenu(item.id, item.name)}
      isHighlighted={item.isHighlightedInNav}
    >
      <span>{item.title}</span>
    </MegaMenuItemButton>
  ) : (
    <MegaMenuItemLink
      key={item.id}
      data-element-id={`MainNav_${formatElementString(item.name)}`}
      data-description={`${item?.name}`}
      active={item.id === id}
      href={link}
      isHighlighted={item.isHighlightedInNav}
      external={isExternalOrTeaser}
      onClick={() => dispatch(setCloseDrawerMegaMenu())}
    >
      <span>{item.title}</span>
    </MegaMenuItemLink>
  )
}

export default NavigationMenuDesktop
