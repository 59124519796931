import React from 'react'
import { Nav, WrapperNavigation } from './NavigationBar.style'
import NavigationMenuDesktop from './components/NavigationMenuDesktop'
import NavigationIcon from './components/NavigationIcon'
import NavigationLogo from './components/NavigationLogo'
import { useSelector } from 'react-redux'
import { openDrawerSearchSelector } from '@features/ui/selector'

const NavigationBar: React.FC = () => {
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)

  return (
    <WrapperNavigation>
      <Nav>
        <NavigationLogo />
        {!isSearchDrawerOpen && <NavigationMenuDesktop />}
        <NavigationIcon />
      </Nav>
    </WrapperNavigation>
  )
}

export default NavigationBar
