import React, { FC, useMemo, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { v4 as uuid } from 'uuid'
import { CART, WISHLIST } from '@constants/routes'
import { IconButton } from '@components/UI'
import MediaQuery from '@components/UI-CSS/MediaQuery'
import { cartProductCountSelector, orderCompleteSelector } from '@features/order/selector'
import { setOpenDrawerSearch, setHamburgerMenuOpenStatus, setCloseDrawerMegaMenu } from '@features/ui/action'
import { openDrawerSearchSelector, hamburgerMenuOpenSelector } from '@features/ui/selector'
import { wishlistItemsCountSelector } from '@features/wishList/selector'
import { NavIcon, NavIconWrapper, NavTinyChip } from './NavigationIcon.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const NavigationIcon: React.FC = memo(() => {
  const router = useRouter()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const orderComplete = useSelector(orderCompleteSelector)
  const cartItemCount = useSelector(cartProductCountSelector)
  const isSearchOpenDrawer = useSelector(openDrawerSearchSelector)
  const isHamburgerDrawerOpen = useSelector(hamburgerMenuOpenSelector)
  const wishlistItemCount = useSelector(wishlistItemsCountSelector)

  const headerCTAIcons: {
    buttonAriaLabel: string
    children: React.ReactNode
    containerProps?: Record<string, string | boolean | undefined>
    icon?: FC
    isHidden?: boolean
    buttonOnClick(): void
  }[] = useMemo(
    () => [
      {
        buttonAriaLabel: t('Header.Actions.Search'),
        buttonOnClick: () => dispatch(setOpenDrawerSearch(!isSearchOpenDrawer)),
        children: <SVGIcon library="global" name="search" />,
        containerProps: {
          'data-element-id': 'MainNav_Search_Open',
          'data-testid': 'search-desktop-largetablet-element',
        },
        isHidden: false,
      },
      {
        buttonAriaLabel: `${t('Header.Actions.WishList')}`,
        buttonOnClick: () => router.push(`/${WISHLIST}`),
        children: (
          <NavIconWrapper>
            {+wishlistItemCount > 0 ? (
              <SVGIcon library="favorite" name="fav-heart-filled" />
            ) : (
              <SVGIcon library="favorite" name="fav-heart" />
            )}
            {+wishlistItemCount > 0 && <NavTinyChip label={wishlistItemCount} />}
          </NavIconWrapper>
        ),
        isHidden: false,
      },
      {
        buttonAriaLabel: t('Header.Actions.Cart'),
        buttonOnClick: () => {
          router.push(`/${CART}`)
          dispatch(setCloseDrawerMegaMenu())
        },
        children: (
          <NavIconWrapper>
            <SVGIcon library="global" name="bag-checkout" />
            {!orderComplete && +cartItemCount > 0 && <NavTinyChip label={`${cartItemCount}`} />}
          </NavIconWrapper>
        ),
        containerProps: { 'data-element-id': 'MainNav_Bag', 'data-description': 'Bag' },
        isHidden: false,
      },
      {
        buttonAriaLabel: t('Header.Actions.Hamburger'),
        buttonOnClick: () => {
          dispatch(setHamburgerMenuOpenStatus(!isHamburgerDrawerOpen))
        },
        children: <SVGIcon library="global" name="hamburger-menu" />,
        containerProps: {
          'data-element-id': 'MainNav_Menu',
          'data-description': 'Menu',
          size: 'tablet',
        },
      },
    ],
    [cartItemCount, dispatch, isHamburgerDrawerOpen, isSearchOpenDrawer, orderComplete, router, t, wishlistItemCount]
  )

  return (
    <NavIcon>
      {headerCTAIcons.map(({ buttonAriaLabel, children, containerProps, buttonOnClick }, i) => {
        return (
          <MediaQuery key={`nav-icon-${uuid()}`} {...containerProps}>
            <IconButton
              aria-label={buttonAriaLabel}
              onClick={buttonOnClick}
              sx={{ color: theme => theme.palette.text.dark.primary }}
              data-testid={`${buttonAriaLabel?.toLowerCase()}Open`}
            >
              {children}
            </IconButton>
          </MediaQuery>
        )
      })}
    </NavIcon>
  )
})

export default NavigationIcon
